import _ from "lodash";
import i18n from "@/plugins/i18n";
import store from "@/store";

const services = localStorage.getItem("services").split(",");

const getResellerServices = () => {
  let resellerServices = [
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_PHISHING"),
      name: i18n.t("admin.form.surveyflow.surveyflows.phishing"),
      service: "phishing"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_ABS"),
      name: i18n.t("admin.form.surveyflow.surveyflows.abs"),
      service: "abs"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_FEDERATION"),
      name: i18n.t("admin.form.surveyflow.surveyflows.federation"),
      service: "federation"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_NEUROGAITAS"),
      name: i18n.t("admin.form.surveyflow.surveyflows.neurophishing"),
      service: "neurogaitas"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_SCIM"),
      name: i18n.t("admin.form.surveyflow.surveyflows.scim"),
      service: "scim"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_SMISHING"),
      name: i18n.t("admin.form.surveyflow.surveyflows.smishing"),
      service: "smishing"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_SMISHGAITAS"),
      name: i18n.t("admin.form.surveyflow.surveyflows.neurosmishing"),
      service: "smishgaitas"
    },
    {
      id: store.getters["Constants/getConstant"](
        "SERVICETYPE_GAMING_ACHIEVEMENTS"
      ),
      name: i18n.t("admin.form.surveyflow.surveyflows.gaming_achievements"),
      service: "gaming_achievements"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_GAMING_RANKING"),
      name: i18n.t("admin.form.surveyflow.surveyflows.gaming_ranking"),
      service: "gaming_ranking"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_GAMING_GAMES"),
      name: i18n.t("admin.form.surveyflow.surveyflows.gaming_games"),
      service: "gaming_games"
    }
  ];

  return resellerServices;
};

const formatServicesById = servicesIds => {
  let formated = [];

  _.each(servicesIds, id => {
    switch (parseInt(id)) {
      case store.getters["Constants/getConstant"]("SERVICETYPE_AWARENESS"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_AWARENESS"),
          name: i18n.t("admin.form.surveyflow.surveyflows.awareness"),
          service: "awareness"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_WELLBEING"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_WELLBEING"),
          name: i18n.t("admin.form.surveyflow.surveyflows.wellbeing"),
          service: "wellbeing"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_RISK"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_RISK"),
          name: i18n.t("admin.form.surveyflow.surveyflows.risk"),
          service: "risk"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_PHISHING"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_PHISHING"),
          name: i18n.t("admin.form.surveyflow.surveyflows.phishing"),
          service: "phishing"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_ABS"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_ABS"),
          name: i18n.t("admin.form.surveyflow.surveyflows.abs"),
          service: "abs"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_FEDERATION"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_FEDERATION"),
          name: i18n.t("admin.form.surveyflow.surveyflows.federation"),
          service: "federation"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_NEUROGAITAS"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_NEUROGAITAS"),
          name: i18n.t("admin.form.surveyflow.surveyflows.neurophishing"),
          service: "neurogaitas"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_BURNOUT"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_BURNOUT"),
          name: i18n.t("admin.form.surveyflow.surveyflows.burnout"),
          service: "burnout"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_SCIM"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_SCIM"),
          name: i18n.t("admin.form.surveyflow.surveyflows.scim"),
          service: "scim"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_SMISHING"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_SMISHING"),
          name: i18n.t("admin.form.surveyflow.surveyflows.smishing"),
          service: "smishing"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_SMISHGAITAS"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_SMISHGAITAS"),
          name: i18n.t("admin.form.surveyflow.surveyflows.neurosmishing"),
          service: "smishgaitas"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_IMPACT"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_IMPACT"),
          name: i18n.t("admin.form.surveyflow.surveyflows.impact"),
          service: "impact"
        });
        break;
      case store.getters["Constants/getConstant"](
        "SERVICETYPE_SOCIALENGINEERING"
      ):
        formated.push({
          id: store.getters["Constants/getConstant"](
            "SERVICETYPE_SOCIALENGINEERING"
          ),
          name: i18n.t("admin.form.surveyflow.surveyflows.socialengineering"),
          service: "socialengineering"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_ARCHETYPE"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_ARCHETYPE"),
          name: i18n.t("admin.form.surveyflow.surveyflows.archetype"),
          service: "archetype"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_CUSTOM_SURVEYS"):
        formated.push({
          id: store.getters["Constants/getConstant"](
            "SERVICETYPE_CUSTOM_SURVEYS"
          ),
          name: i18n.t("admin.form.surveyflow.surveyflows.custom_surveys"),
          service: "custom_surveys"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_GAMING_RANKING"):
        formated.push({
          id: store.getters["Constants/getConstant"](
            "SERVICETYPE_GAMING_RANKING"
          ),
          name: i18n.t("admin.form.surveyflow.surveyflows.gaming_ranking"),
          service: "gaming_ranking"
        });
        break;
      case store.getters["Constants/getConstant"](
        "SERVICETYPE_GAMING_ACHIEVEMENTS"
      ):
        formated.push({
          id: store.getters["Constants/getConstant"](
            "SERVICETYPE_GAMING_ACHIEVEMENTS"
          ),
          name: i18n.t("admin.form.surveyflow.surveyflows.gaming_achievements"),
          service: "gaming_achievements"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_GAMING_GAMES"):
        formated.push({
          id: store.getters["Constants/getConstant"](
            "SERVICETYPE_GAMING_GAMES"
          ),
          name: i18n.t("admin.form.surveyflow.surveyflows.gaming_games"),
          service: "gaming_games"
        });
        break;
      case store.getters["Constants/getConstant"]("SERVICETYPE_MULTI_RISK"):
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_MULTI_RISK"),
          name: i18n.t("admin.form.surveyflow.surveyflows.multi_risk"),
          service: "multi_risk"
        });
        break;
      default:
        formated.push({
          id: parseInt(id),
          name: "SERVICE_" + parseInt(id)
        });
    }
  });

  return formated;
};

const formatServicesByName = () => {
  let services = localStorage.getItem("services").split(",");
  let formated = [];

  _.each(services, service => {
    switch (service) {
      case "awareness":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_AWARENESS"),
          name: i18n.t("admin.form.surveyflow.surveyflows.awareness")
        });
        break;
      case "wellbeing":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_WELLBEING"),
          name: i18n.t("admin.form.surveyflow.surveyflows.wellbeing")
        });
        break;
      case "risk":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_RISK"),
          name: i18n.t("admin.form.surveyflow.surveyflows.risk")
        });
        break;
      case "phishing":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_PHISHING"),
          name: i18n.t("admin.form.surveyflow.surveyflows.phishing")
        });
        break;
      case "abs":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_ABS"),
          name: i18n.t("admin.form.surveyflow.surveyflows.abs")
        });
        break;
      case "federation":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_FEDERATION"),
          name: i18n.t("admin.form.surveyflow.surveyflows.federation")
        });
        break;
      case "neurogaitas":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_NEUROGAITAS"),
          name: i18n.t("admin.form.surveyflow.surveyflows.neurophishing")
        });
        break;
      case "burnout":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_BURNOUT"),
          name: i18n.t("admin.form.surveyflow.surveyflows.burnout")
        });
        break;
      case "scim":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_SCIM"),
          name: i18n.t("admin.form.surveyflow.surveyflows.scim")
        });
        break;
      case "smishing":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_SMISHING"),
          name: i18n.t("admin.form.surveyflow.surveyflows.smishing")
        });
        break;
      case "smishgaitas":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_SMISHGAITAS"),
          name: i18n.t("admin.form.surveyflow.surveyflows.neurosmishing")
        });
        break;
      case "impact":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_IMPACT"),
          name: i18n.t("admin.form.surveyflow.surveyflows.impact")
        });
        break;
      case "socialengineering":
        formated.push({
          id: store.getters["Constants/getConstant"](
            "SERVICETYPE_SOCIALENGINEERING"
          ),
          name: i18n.t("admin.form.surveyflow.surveyflows.socialengineering")
        });
        break;
      case "archetype":
        formated.push({
          id: store.getters["Constants/getConstant"]("SERVICETYPE_ARCHETYPE"),
          name: i18n.t("admin.form.surveyflow.surveyflows.archetype")
        });
        break;
      case "gaming_ranking":
        formated.push({
          id: store.getters["Constants/getConstant"](
            "SERVICETYPE_GAMING_RANKING"
          ),
          name: i18n.t("admin.form.surveyflow.surveyflows.gaming_ranking")
        });
        break;
      case "gaming_achievements":
        formated.push({
          id: store.getters["Constants/getConstant"](
            "SERVICETYPE_GAMING_ACHIEVEMENTS"
          ),
          name: i18n.t("admin.form.surveyflow.surveyflows.gaming_achievements")
        });
        break;
      case "gaming_games":
        formated.push({
          id: store.getters["Constants/getConstant"](
            "SERVICETYPE_GAMING_GAMES"
          ),
          name: i18n.t("admin.form.surveyflow.surveyflows.gaming_games")
        });
        break;
      case "custom_surveys":
        formated.push({
          id: store.getters["Constants/getConstant"](
            "SERVICETYPE_CUSTOM_SURVEYS"
          ),
          name: i18n.t("admin.form.surveyflow.surveyflows.custom_surveys")
        });
        break;
    }
  });

  return formated;
};

export {
  services,
  formatServicesById,
  formatServicesByName,
  getResellerServices
};
