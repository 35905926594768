<template>
  <div>
    <div class="subheader py-2 py-lg-6 subheader-transparent">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">
              {{ this.$t("admin.form.update_companyChildren") }}
            </h5>
            <!--end::Page Title-->
          </div>
          <!--end::Page Heading-->
        </div>
        <b-button
          @click="$router.push({ name: 'Company' })"
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder mr-2 text-uppercase"
        >
          <div class="d-flex">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
        <!--end::Info-->
      </div>
    </div>
    <basic-form
      @callGetRegions="callGetRegionsByCountry"
      :form="form"
      :fields="fields"
      :save="save"
      :error="error"
      :errors="errors"
      :isSubmitting="isSubmitting"
      v-if="loaded"
    />
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import {
  validZipCode,
  validText,
  validDigits,
  validateWhitespace,
  validateAddress,
  validateDomain,
  validMaxLength,
  validUrl,
  validRFC5322
} from "@/utils/validators";
import {
  getAdminStakeholder,
  updateStakeholder,
  updateStakeholderLogo,
  getUserAdminCompanies
} from "@/api/admin/stakeholders.api";
import {
  getCountries,
  getRegions,
  getRegionsByCountry,
  getTimezones
} from "@/api/misc.api";
import { mapActions, mapGetters } from "vuex";
import BasicForm from "@/components/form/BasicForm.component";
import { getError } from "@/utils/helpers";
import {
  getResellerServices,
  formatServicesById
} from "@/utils/helpers-services";

export default {
  name: "CompanyUpdate",
  components: {
    BasicForm
  },
  data() {
    return {
      stakeholderRelationShip: localStorage.getItem("stakeholderRelationShip"),
      companyChildren: {},
      companyParent: {},
      loaded: false,
      errors: [],
      error: false,
      form: {},
      regionsByCountry: [],
      countries: [],
      timezones: [],
      services: [],
      previousServices: [],
      file: File,
      stakeholderId: localStorage.getItem("stakeholderId"),
      childrenCompanies: [],
      isSubmitting: false
    };
  },
  methods: {
    ...mapActions("Admin", [
      "loadCompanySelected",
      "loadAdminCompanies",
      "loadCompanyUpdated"
    ]),
    callGetError(code, params) {
      return getError(code, params);
    },
    checkServices() {
      let text = "";
      let checkFederation =
        !_.find(this.form.services, {
          id: parseInt(this.getConstant("SERVICETYPE_FEDERATION"))
        }) &&
        _.find(this.previousServices, {
          id: parseInt(this.getConstant("SERVICETYPE_FEDERATION"))
        });
      let checkScim =
        !_.find(this.form.services, {
          id: parseInt(this.getConstant("SERVICETYPE_SCIM"))
        }) &&
        _.find(this.previousServices, {
          id: parseInt(this.getConstant("SERVICETYPE_SCIM"))
        });

      if (checkFederation) {
        text += this.$t("admin.popup.update.federation") + "\n";
      }
      if (checkScim) {
        text += this.$t("admin.popup.update.scim") + "\n";
      }

      return text;
    },
    getServiceIds(data) {
      let result = {};
      let hasAbs = _.find(
        data.services,
        e => e.id == this.getConstant("SERVICETYPE_ABS")
      );

      let servicesIds = {};
      if (data.services) {
        servicesIds = _.map(data.services, service => service.id);
      }
      if (!hasAbs) {
        result = {
          USER: servicesIds,
          CONTROLLER: servicesIds,
          ADMIN: servicesIds
        };
      } else {
        let removedServices = _.filter(
          servicesIds,
          el => el != this.getConstant("SERVICETYPE_ABS")
        );
        result = {
          USER: _.clone(removedServices),
          CONTROLLER: _.clone(removedServices),
          ADMIN: _.clone(removedServices)
        };
        _.foreach(_.split(data.service_abs.id, "_"), element => {
          result[_.upperCase(element)].push(
            this.getConstant("SERVICETYPE_ABS")
          );
        });
      }
      return result;
    },
    updateCompany(data) {
      let formattedForm = {
        name: data.company,
        services: this.getServiceIds(data),
        fiscalCode: data.fiscalCode,
        address: data.address,
        domain: data.domain,
        zipCode: data.zipCode,
        relationShip: data.relationShip?.id || "CUSTOMER",
        stakeholderCompanyId: data.stakeholderCompanyId?.stakeholderId || null,
        emailSupport: data.emailSupport || "soporte_usuarios@kymatio.com",
        emailNotification: data.emailSupport || "soporte_usuarios@kymatio.com",
        environment: {
          timezone: data.timezone,
          languages: {
            default: data.default_language,
            list: this.companySelected.environment.languages.list
          },
          passwords: {
            amount: data.amount,
            expiration: `${data.expiration}days`
          },
          notifications: {
            sendemail: data.sendemail ? "YES" : "NO",
            period: {
              user: data.user,
              manager: data.manager
            }
          }
        }
      };

      if (data.regionId) {
        formattedForm.regionId = data.regionId.regionId;
      }

      if (this.hasModule(["federation"])) {
        if (!formattedForm.servicesConfiguration) {
          formattedForm.servicesConfiguration = {};
        }
        if (!_.isEmpty(data.urlMetadataClient)) {
          formattedForm.servicesConfiguration.saml = {
            idp: data.urlMetadataClient,
            fields: {
              email:
                "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
            }
          };
        }
      }

      let formData = new FormData();

      if (data.logo) {
        const fileName = `logo.${data.logo.name.split(".").pop()}`;
        this.file = new File([data.logo], fileName, { type: data.logo.type });
        formData.append("logo", this.file);
      }

      if (!this.errors.length) {
        updateStakeholder("companies", this.$route.params.id, formattedForm)
          .then(response => {
            let company = response.data.records;
            if (data.logo) {
              updateStakeholderLogo(
                "companies",
                this.$route.params.id,
                formData
              )
                .then(() => {
                  this.$root.$bvToast.toast(
                    this.$t("admin.form.success.update_companyChildren_body", {
                      companyChildren: company.name
                    }),
                    {
                      title: this.$t(
                        "admin.form.success.update_companyChildren_header"
                      ),
                      variant: "success",
                      solid: true,
                      toaster: "b-toaster-top-center"
                    }
                  );
                  this.loadCompanySelected();
                  this.$router.push({
                    name: "Company"
                  });
                })
                .catch(error => {
                  this.errors.push({
                    name: error.response.data.records.userMessage,
                    code: error.response.data.records.applicationCode,
                    entity: "company"
                  });
                  this.$root.$bvToast.toast(
                    this.callGetError(
                      error.response.data.records.applicationCode
                    ),
                    {
                      title: this.callGetError("update_company", {
                        companyChildren: this.form.name
                      }),
                      variant: "danger",
                      solid: true,
                      toaster: "b-toaster-top-center"
                    }
                  );
                });
            } else {
              this.$root.$bvToast.toast(
                this.$t("admin.form.success.update_companyChildren_body", {
                  companyChildren: company.name
                }),
                {
                  title: this.$t(
                    "admin.form.success.update_companyChildren_header"
                  ),
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-top-center"
                }
              );
              this.loadCompanySelected();
              this.$router.push({
                name: "Company"
              });
            }
          })
          .catch(error => {
            this.errors.push({
              name: error.response.data.records.userMessage,
              code: error.response.data.records.applicationCode,
              entity: "company"
            });
            this.$root.$bvToast.toast(
              this.callGetError(error.response.data.records.applicationCode),
              {
                title: this.callGetError("update_company", {
                  companyChildren: this.form.name
                }),
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center"
              }
            );
          });
      }
    },
    save(data) {
      this.isSubmitting = true;
      this.error = false;
      this.errors = [];

      if (this.checkServices() !== "") {
        this.$swal({
          title: this.checkServices(),
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: this.$t("generic.buttons.progress"),
          denyButtonText: this.$t("generic.buttons.back")
        }).then(result => {
          if (result.isConfirmed) {
            this.updateCompany(data);
          }
        });
      } else {
        this.updateCompany(data);
      }
      this.isSubmitting = false;
    },
    callGetRegionsByCountry(selectedOptions) {
      if (selectedOptions) {
        getRegionsByCountry(selectedOptions.countryId).then(
          regionsByCountryResponse => {
            if (this.form.regionId) {
              this.form.regionId = "";
            }
            this.regionsByCountry = regionsByCountryResponse.data.records;
            this.loaded = true;
          }
        );
      }
    },
    getServiceABS(services) {
      let hasAdmin = _.includes(
        services.ADMIN,
        this.getConstant("SERVICETYPE_ABS")
      );
      let hasController = _.includes(
        services.CONTROLLER,
        this.getConstant("SERVICETYPE_ABS")
      );
      let hasUser = _.includes(
        services.USER,
        this.getConstant("SERVICETYPE_ABS")
      );
      if (hasAdmin && hasController && hasUser) {
        return {
          id: "admin_controller_user",
          value: this.$t(`admin.form.service_abs.admin_controller_user`)
        };
      } else if (hasAdmin && hasController) {
        return {
          id: "admin_controller",
          value: this.$t(`admin.form.service_abs.admin_controller`)
        };
      } else {
        return { id: "admin", value: this.$t(`admin.form.service_abs.admin`) };
      }
    }
  },
  computed: {
    ...mapGetters("Auth", ["hasModule"]),
    ...mapGetters("Admin", ["companySelected"]),
    ...mapGetters("Constants", ["getConstant"]),
    fields() {
      let result = [
        {
          id: "company",
          required: true,
          type: "text",
          validation:
            validText(this.form.company) &&
            validMaxLength(this.form.company, 255)
        },
        {
          id: "domain",
          type: "text",
          required: true,
          validation: validateDomain(this.form.domain),
          disabled: true
        },
        {
          id: "address",
          type: "text",
          required: true,
          validation:
            validateAddress(this.form.address) &&
            validMaxLength(this.form.address, 255)
        },
        {
          id: "zipCode",
          type: "text",
          required: true,
          validation: validZipCode(this.form.zipCode)
        },
        {
          id: "fiscalCode",
          type: "text",
          required: true,
          validation:
            validText(this.form.fiscalCode) &&
            validateWhitespace(this.form.fiscalCode) &&
            validMaxLength(this.form.fiscalCode, 45)
        },
        {
          id: "emailSupport",
          type: "text",
          required: false,
          validation: validRFC5322(this.form.emailSupport)
        }
      ];

      if (
        this.companyParent?.relationShip === "RESELLER" &&
        parseInt(this.$route.params.id) !==
          parseInt(localStorage.getItem("companyId"))
      ) {
        result.push({
          id: "services",
          type: "multiselect",
          label: "name",
          trackBy: "id",
          options: this.servicesOptions
        });
        result.push({
          id: "service_abs",
          type: "select",
          info: true,
          required: true,
          label: "value",
          options: [
            {
              id: "admin_controller",
              value: this.$t(`admin.form.service_abs.admin_controller`)
            },
            { id: "admin", value: this.$t(`admin.form.service_abs.admin`) },
            {
              id: "admin_controller_user",
              value: this.$t(`admin.form.service_abs.admin_controller_user`)
            }
          ],
          condition:
            _.find(
              this.form.services,
              e => e.id == this.getConstant("SERVICETYPE_ABS")
            ) !== undefined
        });
      }

      if (this.stakeholderRelationShip === "RESELLER") {
        result.push({
          id: "relationShip",
          type: "select",
          required: true,
          label: "value",
          options: [
            { id: "RESELLER", value: this.$t(`admin.form.reseller_field`) },
            { id: "CUSTOMER", value: this.$t(`admin.form.customer_field`) }
          ]
        });

        result.push({
          id: "stakeholderCompanyId",
          type: "select",
          label: "name",
          required: true,
          options: this.childrenCompanies
        });
      }

      result = [
        ...result,
        {
          id: "timezone",
          type: "select",
          required: true,
          options: this.timezones
        },
        {
          id: "default_language",
          type: "select",
          required: true,
          options: this.companyChildren?.environment?.languages?.list
        },
        {
          id: "country",
          type: "select",
          required: true,
          options: this.countries,
          label: "country",
          select: this.callGetRegionsByCountry
        },
        {
          id: "regionId",
          type: "select",
          required: false,
          label: "region",
          options: this.regionsByCountry
        },
        {
          id: "logo",
          type: "file",
          required: false
        },
        {
          id: "amount",
          type: "text",
          required: true,
          validation:
            validDigits(this.form.amount) &&
            validateWhitespace(this.form.amount)
        },
        {
          id: "expiration",
          type: "text",
          required: true,
          validation:
            validDigits(this.form.expiration) &&
            validateWhitespace(this.form.expiration)
        },
        {
          id: "user",
          type: "text",
          required: true,
          validation:
            validDigits(this.form.user) && validateWhitespace(this.form.user)
        },
        {
          id: "manager",
          type: "text",
          required: true,
          validation:
            validDigits(this.form.manager) &&
            validateWhitespace(this.form.manager)
        },
        {
          id: "sendemail",
          type: "boolean",
          required: true,
          options: {
            checked: this.$t(`admin.form.yes`),
            unchecked: this.$t(`admin.form.no`)
          }
        },
        {
          id: "scim",
          type: "button",
          size: 12,
          condition: this.hasModule(["scim"]),
          token: this.companyChildren?.servicesConfiguration.scim?.token
        },
        {
          id: "urlMetadataClient",
          type: "saml_configuration",
          validation:
            validUrl(this.form.urlMetadataClient) ||
            _.isEmpty(this.form.urlMetadataClient),
          condition: this.hasModule(["federation"]),
          size: 12,
          required: false,
          url:
            process.env.VUE_APP_SAML_SERVER +
            "/" +
            process.env.VUE_APP_API_VERSION +
            this.companyChildren.code +
            "/metadata"
        }
      ];

      return _.filter(result, field => field.condition !== false);
    },
    servicesOptions() {
      let companyServices = formatServicesById(this.companyChildren.services);
      let resellerServices = getResellerServices();

      let services = _.uniqBy(
        _.concat(companyServices, resellerServices),
        service => {
          return service.id;
        }
      );

      services = _.map(services, service => {
        let disabled = !_.find(resellerServices, { id: service.id });
        return { ...service, $isDisabled: disabled };
      });

      return services;
    }
  },
  mounted() {
    var requests = [
      getTimezones(),
      getCountries(),
      getRegions(),
      getAdminStakeholder("companies", this.$route.params.id, {
        name: true,
        fiscalCode: true,
        address: true,
        logo: true,
        domain: true,
        regionId: true,
        zipCode: true,
        email: true,
        relationShip: true,
        environment: true,
        countryId: true,
        emailSupport: true,
        releationShip: true,
        stakeholderCompanyId: true,
        stakeholderCompanyName: true,
        services: true
      }),
      getUserAdminCompanies(this.stakeholderId, { relationship: "RESELLER" })
    ];

    let adminOfMyCompany = _.includes(
      localStorage.getItem("adminProfiles").split(","),
      localStorage.getItem("companyId")
    );
    if (adminOfMyCompany) {
      requests.push(
        getAdminStakeholder("companies", localStorage.getItem("companyId"), {
          relationShip: true
        })
      );
    }
    axios.all(requests).then(
      axios.spread(
        (
          timezonesRespose,
          countriesResponse,
          regionsResponse,
          companyResponse,
          companiesResponse,
          companyParentResponse
        ) => {
          this.timezones = timezonesRespose.data.records;
          this.countries = countriesResponse.data.records;
          this.regions = regionsResponse.data.records;
          this.companyChildren = companyResponse.data.records;
          if (adminOfMyCompany) {
            this.companyParent = companyParentResponse.data.records;
            this.childrenCompanies.unshift(companyParentResponse.data.records);
          }

          this.childrenCompanies = companiesResponse.data.records.map(function(
            o
          ) {
            return Object.assign(
              {
                id: o.stakeholderId
              },
              _.omit(o, "id")
            );
          });
          this.form = {
            company: this.companyChildren.name,
            fiscalCode: this.companyChildren.fiscalCode,
            address: this.companyChildren.address,
            logo: null,
            services: formatServicesById(this.companyChildren.services),
            service_abs: this.getServiceABS(
              this.companyChildren.servicesRaw.distribution
            ),
            domain: this.companyChildren.domain,
            country: _.find(this.countries, {
              countryId: this.companyChildren.countryId
            }),
            regionId: _.find(this.regions, {
              regionId: this.companyChildren.regionId
            }),
            emailSupport: this.companyChildren.emailSupport,
            zipCode: this.companyChildren.zipCode,
            email: `kymatio.controller+${this.companyChildren.domain}@gmail.com`,
            relationShip: {
              id: this.companyChildren.relationShip,
              value: this.$t(
                `admin.form.${this.companyChildren.relationShip.toLowerCase()}_field`
              )
            },
            stakeholderCompanyId: {
              stakeholderId: companyResponse.data.records.stakeholderCompanyId,
              name: companyResponse.data.records.stakeholderCompanyName
            },
            timezone: this.companyChildren.environment?.timezone,
            default_language: this.companyChildren.environment?.languages
              .default,
            language: this.companyChildren.environment?.languages?.list,
            default: this.companyChildren.environment?.languages?.default,
            amount: this.companyChildren.environment?.passwords?.amount,
            expiration: this.companyChildren.environment?.passwords?.expiration?.replace(
              "days",
              ""
            ),
            sendemail:
              this.companyChildren.environment?.notifications?.sendemail ===
              "YES"
                ? true
                : false,
            user: Number(
              this.companyChildren.environment?.notifications?.period?.user
            ),
            manager: Number(
              this.companyChildren.environment?.notifications?.period?.manager
            )
          };

          this.previousServices = this.form.services;

          if (
            this.companyChildren.servicesConfiguration.saml &&
            this.hasModule(["federation"])
          ) {
            this.form.urlMetadataClient = this.companyChildren.servicesConfiguration.saml?.idp;
          }

          this.loaded = true;
        }
      )
    );
  }
};
</script>
